@tailwind base;
@tailwind components;
@tailwind utilities;

* {
    outline: none !important;
}

body {
    font-family: sans-serif, Cairo;
}

[dir="rtl"] body {
    font-family: Cairo;
}

/* splide js */
.splide__pagination {
    @apply flex gap-2 absolute -mt-12 left-0 right-0;
}

.splide__pagination__page {
    @apply border-2 rounded-full w-4 h-4 border-zinc-600;
}

.splide__pagination__page.is-active {
    @apply bg-zinc-600;
}

/* loader svg */
@keyframes circle__svg {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.circle__svg-circle {
    transform-origin: center;
    animation-name: circle__svg;
    animation-duration: 1s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    height: 1px;
}

/* editor content */
.editor-content {
    font-family: sans-serif;
}
.editor-content h2 {
    @apply text-3xl font-semibold mb-6 mt-6;
}
.editor-content h3 {
    @apply text-2xl font-semibold mb-6 mt-6;
}
.editor-content p,
.editor-content blockquote,
.editor-content pre,
.editor-content ul,
.editor-content ol {
    @apply mb-6 text-xl leading-relaxed;
}
.editor-content pre {
    direction: ltr;
}
.editor-content code {
    @apply bg-zinc-100 p-1 rounded text-base;
}
.editor-content .embedded_image {
    @apply my-6 rounded-lg overflow-hidden relative;
}
.editor-content .embedded_image::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 1px solid;
    border-radius: inherit;
    @apply border-black border-opacity-10;
}
.editor-content hr {
    @apply m-12;
}
.editor-content ul,
.editor-content ol {
    @apply px-6;
}
.editor-content ul {
    list-style: disc inside;
}
.editor-content ol {
    list-style: decimal inside;
}
.editor-content ul li:first-child,
.editor-content ol li:first-child {
    margin-top: 8px;
}
[dir="rtl"] .editor-content blockquote {
    @apply px-6 border-r-4;
}
[dir="ltr"] .editor-content blockquote {
    @apply px-6 border-l-4;
}
.editor-content pre.ql-syntax {
    @apply bg-zinc-100 p-4 rounded-lg;
    white-space: pre-wrap;
}
.hljs {
    display: block;
    overflow-x: auto;
    padding: 0.5em;
    color: #333;
    background: #f8f8f8;
}
.hljs-comment,
.hljs-quote {
    color: #998;
    font-style: italic;
}
.hljs-keyword,
.hljs-selector-tag,
.hljs-subst {
    color: #333;
    font-weight: bold;
}
.hljs-number,
.hljs-literal,
.hljs-variable,
.hljs-template-variable,
.hljs-tag .hljs-attr {
    color: #008080;
}
.hljs-string,
.hljs-doctag {
    color: #d14;
}
.hljs-title,
.hljs-section,
.hljs-selector-id {
    color: #900;
    font-weight: bold;
}
.hljs-subst {
    font-weight: normal;
}
.hljs-type,
.hljs-class .hljs-title {
    color: #458;
    font-weight: bold;
}
.hljs-tag,
.hljs-name,
.hljs-attribute {
    color: #000080;
    font-weight: normal;
}
.hljs-regexp,
.hljs-link {
    color: #009926;
}
.hljs-symbol,
.hljs-bullet {
    color: #990073;
}
.hljs-built_in,
.hljs-builtin-name {
    color: #0086b3;
}
.hljs-meta {
    color: #999;
    font-weight: bold;
}
.hljs-deletion {
    background: #fdd;
}
.hljs-addition {
    background: #dfd;
}
.hljs-emphasis {
    font-style: italic;
}
.hljs-strong {
    font-weight: bold;
}

/* img border */
.img-border {
    position: relative;
}
.img-border::after {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    border: 1px solid;
    border-radius: inherit;
    @apply border-black border-opacity-10;
}